<template>
  <v-row>
    <v-row justify="center" max-width="344">
      <v-col cols="12" sm="11">
        <v-form
          ref="form"
          v-model="isvalid"
          lazy-validation
        >
          <v-card>
						<v-card-title align="left" class="headline primary create-disb-title">
							<h5>Create Disbursement</h5>
						</v-card-title>     
            <v-container grid-list-md class="create-disb-container">
							<h4>Disbursement Info</h4>
							<br>
							<v-layout wrap>
								<v-flex xs12 sm12 md12>
									<p>Select Payment Method:</p>
									<v-radio-group v-model="document_type" row>
										<v-radio label="Debit Memo" value="DM"></v-radio>
										<v-radio label="Cheque / CV" value="CV"></v-radio>
										<v-radio label="Online / Others" value="OB"></v-radio>
										<v-radio label="Cheque Write" value="CW"></v-radio>
									</v-radio-group>
								</v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field label="Batch Number" v-model="batch_number" :rules="batch_number_rules" required></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4 v-if="document_type === 'CW'">
									<v-menu
										v-model="batch_datepicker"
										:nudge-right="40"
										transition="scale-transition"
										offset-y
										min-width="290px"
										:close-on-content-click="false"
									>
										<template v-slot:activator="{ on }">
											<v-text-field
												label="Date"
												v-mask="date_format"
												v-model="date"
												required
												:rules="date_rules"
												return-masked-value
												v-on="on"
												mask="YYYY-MM-DD"
												placeholder="YYYY-MM-DD">
											</v-text-field>
										</template>
										<v-date-picker
											color="primary"
											v-model="date"
											:min="getCurrentDate()"
											@change="batchDatepickerSelect(date)"
										></v-date-picker>
									</v-menu>
                </v-flex>
				<v-flex xs12 sm6 md4 v-else>
									<v-menu
										v-model="batch_datepicker"
										:nudge-right="40"
										transition="scale-transition"
										offset-y
										min-width="290px"
										:disabled="document_type != 'OB'"
										:close-on-content-click="false"
									>
										<template v-slot:activator="{ on }">
											<v-text-field
												label="Date"
												v-mask="date_format"
												v-model="date"
												required
												:readonly="document_type != 'OB'"
												:rules="date_rules"
												return-masked-value
												v-on="on"
												mask="YYYY-MM-DD"
												placeholder="YYYY-MM-DD">
											</v-text-field>
										</template>
										<v-date-picker
											color="primary"
											v-model="date"
											@change="batchDatepickerSelect(date)"
										></v-date-picker>
									</v-menu>
                </v-flex>
                <v-flex xs12 sm6 md4>
									<v-autocomplete
										v-model="bank_account_id"
										:items="dataBanks"
										editable
										hide-details
										item-text="code_name"
										item-value="id"
										label="Bank"
										:rules="bank_rules"
									>
									<template slot="item" slot-scope="data">
										{{ data.item.bank_code }} - {{ data.item.bank_acct }} - {{ data.item.acct_type }} - {{(data.item.acct_clss == 'D' ? 'Dollar' : "Peso" )}}
									</template>
									</v-autocomplete>
                </v-flex>
								<v-flex xs12 sm6 md4>
									<v-autocomplete
										v-model="currency_id"
										:items="currency"
										editable
										hide-details
										item-text="cur_desc"
										readonly
										item-value="id"
										label="Currency"
									></v-autocomplete>
                </v-flex>
								<v-flex xs12 sm6 md4 v-if="currency_id !== 1">
                  <v-text-field label="Rate" v-model="dollar_rate"></v-text-field>
                </v-flex>
                <!-- <v-flex xs12 sm6 md4>
                  <v-text-field label="Amount" v-model="amount" required></v-text-field>
                </v-flex> -->
              </v-layout>
				<br>
				<v-layout wrap v-if="Object.keys(po_request).length !== 0">
					<v-flex md12 xs12 sm12>
						<h4>PO Details</h4>
					</v-flex>
					<v-flex xs12 sm6 md4>
						<v-text-field
							v-model="po_request.po_number"
							readonly
							label="PO Number"
						></v-text-field>
					</v-flex>
					<v-flex xs12 sm6 md4>
						<v-text-field
							v-model="po_request.amount"
							readonly
							label="Total PO Amount"
						></v-text-field>
					</v-flex>
					<v-flex xs12 sm6 md4>
						<v-text-field
							v-model="po_request.payment_amount"
							readonly
							label="Total Paid Amount"
						></v-text-field>
					</v-flex>
					<v-flex xs12 sm6 md4>
						<v-text-field
							v-model="po_request.remaining_amount"
							readonly
							label="Remaining Amount to Pay"
						></v-text-field>
					</v-flex>
					<v-flex xs12 sm6 md4>
						<v-text-field
							v-model="payment_amount"
							label="Payment Amount"
							:rules="default_rules"
						></v-text-field>
					</v-flex>
					<v-spacer></v-spacer>
					<v-flex xs12 sm12 md4>
						<h4>PO Attached Files:</h4>
						<v-layout column style="height: 100px">
							<v-flex style="overflow: auto" class="elevation-3">
								<v-data-table
									:headers="attachHeaders"
									:items="JSON.parse(po_request.upload_file)"
									:loading="false"
									class="elevation-1"
									:hide-default-footer="true"
									:hide-default-header="true"
									style="margin:0"
									:items-per-page="1000"
									>
									<template v-slot:[`item.filename`]="{ item }">{{item.title}}</template>
									<template v-slot:[`item.file`]="{ item }">
										<v-icon class="text-right mr-2" color="primary" medium @click="viewItem(item.path)">
											mdi-eye-circle
										</v-icon>
									</template>
								</v-data-table>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
							<v-checkbox 
								v-model="isConfidential" 
								label="Save this disbursement form as Confidential?"
								false-value="0"
								true-value="1"
							></v-checkbox>
							<h4>Disbursement Upload</h4>
							<div class="large-12 medium-12 small-12 cell">
								<label>Files
									<input type="file" id="files" ref="files" multiple v-on:change="handleFileUploads()"/>
								</label>
							</div>
            </v-container>
            <v-divider class="create-disb-divider"></v-divider>
            <v-container class="doc-items-container" grid-list-md>
              <h4>Accounting Entries</h4>
              <p class="caption"></p>
              <trx-header ref="trxHeader" 
								:batch_number.sync="batch_number" 
								:batch_document_date.sync="date" 
								:method_type.sync="method_type" 
								:headerValue.sync="headers" 
								:detailValue.sync="details" 
								:document_type.sync="document_type" 
								:bank_account_id.sync="bank_account_id" 
								:newDetail.sync="newDetail" 
								:currency_id.sync="currency_id" 
								:dollar_rate.sync="dollar_rate" 
								:dataBanks="dataBanks"
								:dataRegularTrx="dataRegularTrx"
								:dataDepts="dataDepts"
								:dataPO="po_request">
							</trx-header>
            </v-container>
            <v-divider class="create-disb-divider"></v-divider>
            <v-card-actions class="card-action-container">
							<div>
								<v-btn text color="blue accent-4" @click="createDialog = true">Create Disbursement</v-btn>
								<v-btn text color="black accent-4" @click="draftDisbursement = true">Save as Draft</v-btn>
								<v-btn dark color="red accent-4" @click="removeStorage('cancel')">Cancel</v-btn>
							</div>
              <!-- <v-btn right @click="generatePDF">Generate PDF (WIP)</v-btn> -->
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>

		<!-- Dialog -->
		<div class="text-center">
			<v-dialog
				v-model="createDialog"
				persistent max-width="600"
			>
				<v-card>
					<v-card-title
						class="headline primary white--text"
						primary-title
					>
						Create Disbursement
					</v-card-title>
					<br>
					<v-card-text>
						Are you sure you want to submit the form?
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							text
							@click="save('create')"
						>
							Save
						</v-btn>
						<v-btn
							color="red"
							text
							@click="createDialog = false"
						>
							Cancel
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
		<div class="text-center">
			<v-dialog
				v-model="draftDisbursement"
				persistent max-width="600"
			>
				<v-card>
					<v-card-title
						class="headline primary white--text"
						primary-title
					>
						Save As Draft
					</v-card-title>
					<br>
					<v-card-text>
						Are you sure you want to save the form as Draft?
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							text
							@click="save('draft')"
						>
							Save as Draft
						</v-btn>
						<v-btn
							color="red"
							text
							@click="draftDisbursement = false"
						>
							Cancel
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
		<div class="text-center">
			<v-dialog
				v-model="createDisbDialog"
				persistent max-width="600"
			>
				<v-card>
					<v-card-title
						class="headline primary white--text"
						primary-title
					>
						Create Disbursement
					</v-card-title>
					<br>
					<v-card-text>
						Sorry, the accounting period for this month is already <b>CLOSED</b>.
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
						<v-spacer></v-spacer>
						<router-link to="/trxdisb">
							<v-btn
								color="primary"
								text
								@click="createDisbDialog = false"
							>
								OK
							</v-btn>
						</router-link>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>

		<!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      right
    >
      {{ error_message }}
      <v-btn
        color="pink"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
	</v-row>
</template>
<script>
import numeral from 'numeral';
import trxHeader from './createTrxHeader';
import { mask } from 'vue-the-mask';
import { mapGetters } from 'vuex';
import { format, getMonth, getYear, parseISO, addMonths } from 'date-fns';
import jsonToFormData from 'json-form-data';

export default {
	directives: {
		mask,
	},
	components: {
		'trx-header': trxHeader,
	},
	data() {
		return {
			level1: {},
			level2: {},
			level3: {},
			currency_id: 1,
			dollar_rate: '',
			batch_datepicker: false,
			newDetail: {},
			document_type: 'DM',
			show: false,
			isvalid: false,
			batch_number: '',
			draftDisbursement: false,
			date_format: '####-##-##',
			date: '',
			upload_files: '',
			upload_file: [],
			bank_account_id: 0,
			snackbar: false,
			isConfidential: '0',
			method_type: '',
			error_message: 'Error: Please fill out the required fields',
			headers:[],
			details:[],
			generated_cheques: [],
			image_title: [],
			file: '',
			payment_amount: '',
			createDialog: false,
			createDisbDialog: false,
			nextPageDepts: '',
			nextPagePayee:'',
			nextPageAcctmainperiod:'',
			nextPageBanks:'',
			dataBanks: [],
			dataAcctmainperiod: [],
			dataDepts: [],
			dataPayee: [],
			dataRegularTrx: [],
			nextPageRegularTrx: '',
			batch_number_rules: [
				v => !!v || 'Batch Number is required',
			],
			date_rules: [
				v => !!v || 'Date is required',
			],
			payee_rules: [
				v => !!v || 'Payee is required',
			],
			bank_rules: [
				v => !!v || 'Bank is required',
			],
			default_rules: [
				v => !!v || 'Required Field',
			],
			attachHeaders: [
				{
					text: 'File Name',
					align: 'left',
					sortable: false,
					value: 'title'
				},
				{ 
					text: 'Actions',
					align: 'right',
					sortable: false,
					value: 'file',
				},
			],
		};
	},
	computed: {
		...mapGetters({
			banks: 'bank/banks',
			currUser: 'auth/currUser',
			success: 'trxdisb/success',
			dist_data: 'trxdisb/dist_data',
			depts: 'dept/depts',
			payeefile: 'payefile/payefiles',
			acctperiodmains: 'acctperiodmain/acctperiodmains',
			currency: 'currency/currencies',
			regulartrx: 'regularTxns/regulartxns',
			approvinglevel: 'approvinglevel/approvinglevels',
			coas: 'coa/coaSubs',
			coaData: 'coa/coaData',
			po_request: 'trxpurchaseorder/poRequest'
		}),
		payees(){
			let data = this.payeefile.filter(e => e.status === 1);
			return data;
		},
		
	},
	watch: {
		approvinglevel: {
			handler(val) {
				val.forEach(level => {
					if (level.level_code == 1){
						this.level1 = {'min_amount': parseFloat(level.min_amount),'max_amount': parseFloat(level.max_amount)};
					} else if (level.level_code == 2){
						this.level2 = {'min_amount': parseFloat(level.min_amount),'max_amount': parseFloat(level.max_amount)};
					} else if (level.level_code == 3){
						this.level3 = {'min_amount': parseFloat(level.min_amount),'max_amount': parseFloat(level.max_amount)};
					}
				});
			}
		},
		payment_amount :{
			handler(val) {
				this.payment_amount = numeral(val).format('0,0.[00]');
			}
		},
		coas: {
			handler(){
				this.getDataCoas();
			}
		},
		document_type: {
			handler(val) {
				this.setBatchNumber(val);
			}
		},
		batch_document_date: {
			handler() {
				this.setStorage();
			}
		},
		batch_number: {
			handler() {
				this.setStorage();
			}
		},
		bank_account_id: {
			handler() {
				this.setStorage();
				this.getDefaultCurrency();
			}
		},
		isConfidential: {
			handler() {
				this.setStorage();
			}
		},
		currency_id: {
			handler() {
				this.setStorage();
			}
		},
		dollar_rate: {
			handler() {
				this.setStorage();
			}
		},
		banks: {
			handler() {
				this.getDataBank();
			}
		},
		depts: {
			handler() {
				this.getDataDepts();
			}
		},
		acctperiodmains: {
			handler() {
				this.getAcctperiodmain();
			}
		},
		regulartrx: {
			handler() {
				this.getRegTrx();
			}
		}
	},
	async mounted() {
		//Gets all the dropdown data for the following items
		this.$store.dispatch('bank/getBanks');
		this.$store.dispatch('dept/getDeptDropdown');
		this.$store.dispatch('payefile/getPayefileDropdown');
		this.$store.dispatch('currency/getCurrencies');
		this.$store.dispatch('regularTxns/getregularTxns');
		this.$store.dispatch('approvinglevel/getApprovingLevels');
		this.$store.dispatch('acctperiodmain/getAcctperiodmains');
		this.$store.dispatch('coa/getCoaDropdown');

		this.payment_amount = '';

		
		//Gets all the data needed for checking
		let dateFormat = this.getCurrentDate();
		let currentMonth = getMonth(parseISO(dateFormat));
		let currentYear = getYear(parseISO(dateFormat));
		let isBookOpen = true;

		//Initializes the function
		this.checkBookStatus(isBookOpen, currentMonth, currentYear, dateFormat);
	},
	methods: {
		init() {
			this.$refs.trxHeader.addHeader;
			this.headers = this.$data.headerSubmitContent;
			this.details = this.$data.details;
			this.generated_cheques = this.$data.generated_cheques;
		},
		getCurrentDate() {
			return format(new Date(), 'yyyy-MM-dd');
		},
		checkBookStatus(isBookOpen, currentMonth, currentYear, dateFormat) {
			//Checks the current Account Period
			this.dataAcctmainperiod.forEach(account_period => {
				if (account_period.period_no === currentMonth && account_period.fiscal_year === currentYear) {
					if (account_period.disbursement === 'CLOSED') {
						isBookOpen = false;
					} else {
						isBookOpen = true;
					}
				} else {
					isBookOpen = true;
				}
			});

			//If the book for the current and future month is open
			if (isBookOpen === true) {
				this.$data.date = dateFormat;
				
				const distData = this.dist_data;
				
				let data = distData.debit_memo;
				
				this.$data.batch_number = data.batch_number;
				
				if (data.date){
					this.$data.date = data.date;
				}

				if (data.bank_account_id){
					this.$data.bank_account_id = data.bank_account_id;
				}

				if (data.isConfidential){
					this.$data.isConfidential = data.isConfidential;
				}

				if (data.disbursement_type) {
					this.$data.disbursement_type = data.disbursement_type;
				}

				if (data.currency_id) {
					this.$data.currency_id = data.currency_id;
				}

				if (data.dollar_rate) {
					this.$data.dollar_rate = data.dollar_rate;
				}
				
				this.setStorage(); 
			} else {
				this.createDisbDialog = true;
			}
		},
		batchDatepickerSelect(date) {
			if (date) {
				let month = getMonth(parseISO(date));
				let year = getYear(parseISO(date));
				this.dataAcctmainperiod.forEach(account_period => {
					if (account_period.period_no === (month + 1) && account_period.fiscal_year === year) {
						if (account_period.disbursement === 'CLOSED') {
							this.snackbar = true;
							this.$data.error_message = 'Accounting Period for this date is already Closed.';
							this.date = this.getCurrentDate();
						}
					}
				});
				this.batch_datepicker = false;
			}
		},
		setExistingBatchDetails(disb_type, dist_data, current_date) {
			let data = dist_data[disb_type];
			this.$data.batch_number = data.batch_number;
			this.$data.bank_account_id = data.bank_account_id;
			this.$data.isConfidential = data.isConfidential;
			this.$data.date = current_date;
			this.$data.currency_id = data.currency_id;
			this.$data.dollar_rate = data.dollar_rate;

			this.newDetail = { headers: dist_data[disb_type].headers, details: dist_data[disb_type].details};
		},
		setStorage() {
			let distData = this.dist_data;
			let data = [];

			switch (this.document_type) {
			case 'CV':
				data = distData.cheque;
				break;
			case 'OB':
				data = distData.online;
				break;
			case 'CW':
				data = distData.cheque_write;
				break;
			default:
				data = distData.debit_memo;
			}

			data['document_type'] = this.document_type;
			data['date'] = format(new Date(), 'yyyy-MM-dd');
			data['bank_account_id'] = this.bank_account_id;
			data['isConfidential'] = this.isConfidential;
			data['currency_id'] = this.currency_id;
			data['dollar_rate'] = this.dollar_rate;
			
			this.$store.commit('trxdisb/SET_DIST_DATA', distData);
		},
		getDefaultCurrency() {
			let acct_class = '';
			this.dataBanks.filter(det => {
				if(det.id === this.bank_account_id){
					acct_class = det.acct_clss;
				}
			});

			this.currency.filter(det => {
				if(det.cur_code === acct_class){
					this.currency_id = det.id;
				}
			});
		},
		removeStorage(from) {
			let data = this.dist_data;
			let fields = ['details', 'headers', 'date', 'bank_account_id', 'isConfidential', 'disbursement_type'];
			
			if('debit_memo' in data){
				this.deleteStorageType(data, 'debit_memo', fields);
			}

			if('cheque' in data){
				this.deleteStorageType(data, 'cheque', fields);
			}

			if('cheque_write' in data){
				this.deleteStorageType(data, 'cheque_write', fields);
			}

			if('online' in data){
				this.deleteStorageType(data, 'online', fields);
			}

			if (from == 'saving'){
				switch (this.disbursement_type) {
				case 'DM':
					delete data.debit_memo;
					break;
				case 'CV':
					delete data.cheque;
					break;
				case 'OB':
					delete data.online;
					break;
				case 'CW':
					delete data.cheque_write; 
					break;
				}
			}
			this.$store.commit('trxdisb/SET_DIST_DATA', data);
			this.$router.push('/trxdisb');
		},
		deleteStorageType(data, disbursement_type, fields) {
			fields.forEach(field => {
				if(field in data[disbursement_type]){
					delete data[disbursement_type][field];
				}
			});
		},
		handleFileUploads(){
			this.upload_files = this.$refs.files.files;
		},
		async generateCheque(counter, due_date, header, details, document_type) {
			let currentDueDate = format(new Date(due_date), 'yyyy-MM-dd');
			console.log('currentduedtae' + currentDueDate);
			const distData = this.dist_data;
			let header_data = [];

			let data = { 
				document_date: this.$data.date,
				document_type: this.document_type,
				edit_by: this.currUser.id
			};

			for (let i = 0; i < counter - 1; i++) {
				let due_date = addMonths(parseISO(currentDueDate), i + 1);
				
				console.log('count' + (i+1));
				console.log('duedate' + due_date);
				let headerDoc = [];

				header_data.forEach((headerDocNum) => {
					if (document_type === 'CW') {
						headerDoc.push(headerDocNum.reference_number);
					} else if (document_type === 'CV') {
						headerDoc.push(headerDocNum.document_number);
					}
				});

				let datas = [];
				let detailData = {};

				if (document_type === 'CW') {
					distData.cheque_write.document_number.map(i => {
						detailData = {document_number: parseInt(i), matched: headerDoc.includes(parseInt(i))};
						datas.push(detailData);
					});
				} else if (document_type === 'CV') {
					distData.cheque.document_number.map(i => {
						detailData = {document_number: parseInt(i), matched: headerDoc.includes(parseInt(i))};
						datas.push(detailData);
					});
				}

				let num = datas.filter(det => {
					return det.matched === false; 
				});

				let unique_id = '';
				// let get_generated_number = '';

				if(num.length > 1){
					unique_id = num[1].document_number;
				}

				
				if (document_type === 'CW') {
					if(!unique_id){
						data['batch_number'] = distData.cheque_write.batch_number;
						await this.$store.dispatch('trxdisb/getDocumentNumber', data).then(response => {
							let data = distData.cheque_write;
							data['document_number'].push(response.data.data.document_number);
							unique_id = parseInt(response.data.data.document_number);
						});
					}
				} else if (document_type === 'CV') {
					let docNumCheque = [];
					await this.$store.dispatch('trxdisb/getDocumentNumberCheque').then(response => {
						docNumCheque = response.data.data;
					});

					unique_id = docNumCheque[0].voucher_number;
				}


				//await get_generated_number;

				let detail_values = [];
				
				details.forEach(detail => {
					detail_values.push({
						section_id: detail.section_id,
						regionalhead_id: detail.regionalhead_id,
						document_number: (document_type == 'CW') ? '' : unique_id,
						document_date: (document_type == 'CW') ? '' : format(new Date(due_date), 'yyyy-MM-dd'),
						account_code: detail.account_code,
						debit_amount: detail.debit_amount,
						currency_id: detail.currency_id,
						credit_amount: detail.credit_amount,
						branch_id: detail.branch_id,
						remarks: detail.remarks,
						reference_number: (document_type == 'CW') ? unique_id : '',
						payee_id: detail.payee_id,
						department_id: detail.department_id,
					});
				});

				let header_value = {
					company_id: header.company_id,
					batch_number: header.batch_number,
					regular_trx_type: header.regular_trx_type,
					disbursement_type: header.disbursement_type,
					regular_trx_selected: header.regular_trx_id,
					document_number: (document_type == 'CW') ? '' : unique_id,
					atc_code: header.atc_code,
					currency_id: header.currency_id,
					dollar_rate: header.dollar_rate,
					vat: header.vat,
					regtrx_indicator: header.regtrx_indicator,
					amount: header.amount,
					regular_trx_id: header.regular_trx_id,
					enable_tax_base: header.enable_tax_base,
					check_type: header.check_type,
					is_pdc: header.check_type === 'PDC' ? 1 : 0,
					payee_bank: header.payee_bank,
					due_date: format(new Date(due_date), 'yyyy-MM-dd'),
					reference_number: (document_type == 'CW') ? unique_id : '',
					details: detail_values,
					overallDebit: header.overallDebit,
					overallCredit: header.overallCredit,
					payee_id: header.payee_id,
					payee_account_id: header.payee_account_id,
					remarks: header.remarks,
					cheque_date: header.cheque_date,
					cheque_number: header.cheque_number,
					voucher_amount: header.voucher_amount,
					voucher_date: header.due_date,
					document_date: (document_type == 'CW') ? '' : format(new Date(due_date), 'yyyy-MM-dd'),
				};

				if(document_type == 'CV'){
					header_value.cheque_amount = header.cheque_amount;
					header_value.cheque_date = header.cheque_date;
					header_value.cheque_number = header.cheque_number;
					header_value.voucher_amount = header.voucher_amount;
					header_value.voucher_date = header.voucher_date;
				}

				if(document_type == 'CW'){
					header_value.cheque_amount = header.cheque_amount;
					header_value.cheque_date = header.cheque_date;
					header_value.cheque_number = header.cheque_number;
					header_value.voucher_amount = header.voucher_amount;
					header_value.voucher_date = header.due_date;
				}

				header_data.push(header_value);
				this.generated_cheques.push(header_value);

				new Promise(resolve => {
					setTimeout(() => {
						resolve(this.generated_cheques);
					}, 1500);
				});
			}

			return this.generated_cheques;
		},
		async save(type) {
			//Validations
			this.isvalid = this.$refs.form.validate();

			if (this.isvalid) {
				//Initialize Payload
				let data = {};
				let detail = [];
				let header = [];
				this.generated_cheques = [];
				let cheque_count = 0;
				let get_generated_cheques = [];
				let dollar_rate = 0;

				data = {};
				header = [];
				detail = [];
				data.image_title = [];
				data.upload_file = [];

				detail = this.details;

				data.headers = header;
				data.details = detail;

				const filterRegTrx = this.headers.filter(header => header.disbursement_type === '1');
				
				if (filterRegTrx.length > 0) {
					this.saveRegularTrx(filterRegTrx, data);
				}

				var updatedDetails = [];

				this.headers.forEach((headerData) => {
					let payeeItemsHeaders = headerData.payee_id;
					
					if (typeof headerData.payee_id === 'number') {
						payeeItemsHeaders = headerData.payee_id;
					} else if (typeof headerData.payee_id === 'object') {
						payeeItemsHeaders = headerData.payee_id.id;
					}
					let headerObject = {
						company_id: headerData.company_id,
						batch_number: this.$data.batch_number,
						document_number: this.$data.document_type == 'CW' ? '' : headerData.document_number,
						atc_code: headerData.atc_code ? headerData.atc_code : 0,
						currency_id: this.currency_id,
						dollar_rate: headerData.dollar_rate ? headerData.dollar_rate : dollar_rate,
						vat: headerData.vat,
						amount: headerData.amount,
						regular_trx_id: headerData.regular_trx_type === '1' ? headerData.regular_trx_id : (headerData.regular_trx_type === '2' ? process.env.VUE_APP_REGULAR_TRX_ID_PLACEHOLDER : 0),
						overallDebit: headerData.overallDebit.replace(/,/g, ''),
						overallCredit: headerData.overallCredit.replace(/,/g, ''),
						payee_id: payeeItemsHeaders,
						remarks: headerData.remarks,
						status: this.getStatus(type, headerData.auto_debit),//type === 'create' ? ((headerData.auto_debit == '1') ? 6 : 3) : 1,
						document_date: this.$data.date, // this.$data.document_type == 'CW' ? '' : 
						is_pdc: headerData.check_type === 'PDC' ? 1 : 0,
						is_confidential: this.$data.isConfidential,
						document_type: this.$data.document_type,
						bank_account_id: this.$data.bank_account_id,
						regtrx_indicator: headerData.regular_trx_type,
						edit_by: this.currUser.id,
						payee_account_id: headerData.payee_account_id,
						reference_number: this.$data.document_type == 'CW' ? headerData.reference_number : '',
						due_date: headerData.due_date,
						auto_debit: (headerData.auto_debit !== undefined) ? headerData.auto_debit : 0
					};

					if(Object.keys(this.po_request).length !== 0){
						headerObject.integrate_from = 3;
						headerObject.reference_number = this.po_request.po_number;
						headerObject.po_payment = parseFloat(this.payment_amount.replace(/,/g, ''));
					}

					if(this.$data.document_type == 'CV'){
						headerObject.cheque_date = headerData.cheque_date;
						headerObject.cheque_number = headerData.cheque_number;
						headerObject.cheque_amount = headerData.cheque_amount;
						headerObject.voucher_amount = headerData.voucher_amount;
						headerObject.voucher_date = headerData.voucher_date;
						if(headerData.check_type === 'PDC'){
							headerObject.document_date = headerData.voucher_date;	
						}
					} else if(this.$data.document_type == 'CW') {
						headerObject.cheque_date = headerData.cheque_date;
						headerObject.cheque_amount = headerData.cheque_amount;
						headerObject.cheque_number = headerData.cheque_number;
						headerObject.voucher_amount = headerData.voucher_amount;
						headerObject.voucher_date = headerData.due_date;
					}

					if(headerObject.payee_account_id === '' || headerObject.payee_account_id === undefined){
						delete headerObject.payee_account_id;
					}

					header.push(headerObject);

					const filterDetails = this.details.filter(detail => detail.document_number === headerData.document_number);					

					filterDetails.forEach((detail) => {
						detail.debit_amount = detail.debit_amount.replace(/,/g, '');
						detail.credit_amount = detail.credit_amount.replace(/,/g, '');
						detail.remarks = detail.remarks ? detail.remarks : 'New Entry';

						updatedDetails.push({
							batch_number: this.$data.batch_number,
							account_code: detail.account_code,
							branch_id: detail.branch_id,
							credit_amount: detail.credit_amount,
							debit_amount: detail.debit_amount,
							department_id: detail.department_id,
							document_date: this.$data.document_type == 'CV' ? (headerData.check_type === 'PDC' ? headerData.voucher_date :  this.date) : this.date,
							document_number: detail.document_number,
							payee_id: detail.payee_id,
							regionalhead_id: detail.regionalhead_id,
							currency_id: detail.currency_id,
							remarks: detail.remarks,
							section_id: (detail.section_id !== undefined || detail.section_id !== '') ? detail.section_id : 0,
							reference_number: this.$data.document_type == 'CW' ? headerObject.reference_number : '',
							edit_by: this.currUser.id
						});
						
						// delete detail.sections;
						// delete detail.branches;

						if(detail.section_id === '' || detail.section_id === undefined){
							detail.section_id = 0;
						}

						//this.$refs.trxHeader.setDetailValue(detail.payee_id, index, detail.document_number, detail.section_id);
						
					});

					data.details = updatedDetails;
          
					if ((this.$data.document_type == 'CW' && headerObject.is_pdc == 1) || (this.$data.document_type == 'CV' && headerObject.is_pdc == 1)) {
						cheque_count = headerData.cheque_count;
						get_generated_cheques = this.generateCheque(headerData.cheque_count, headerData.due_date, headerObject, updatedDetails, this.$data.document_type);
					}
				});
        
				if ((this.$data.document_type == 'CW' && data.headers[0].is_pdc == 1) || (this.$data.document_type == 'CV' && data.headers[0].is_pdc == 1)) {
					this.$store.commit('app/SET_IS_LOADING', true, { root: true });
					await get_generated_cheques;
					this.$store.commit('app/SET_IS_LOADING', false, { root: true });

					if (parseInt(cheque_count - 1) === this.generated_cheques.length) {
						// this.generated_cheques.splice(-1);
						this.generated_cheques.forEach(headerData => {
							let payeeItemsHeaders = headerData.payee_id;

							if (typeof headerData.payee_id === 'number') {
								payeeItemsHeaders = headerData.payee_id;
							} else if (typeof headerData.payee_id === 'object') {
								payeeItemsHeaders = headerData.payee_id.id;
							}

							data.headers.push({
								company_id: headerData.company_id,
								batch_number: this.$data.batch_number,
								document_number: this.$data.document_type == 'CW' ? '' : headerData.document_number,
								atc_code: headerData.atc_code ? headerData.atc_code : 0,
								currency_id: this.currency_id,
								dollar_rate: headerData.dollar_rate,
								is_pdc: headerData.is_pdc,
								vat: headerData.vat,
								amount: headerData.amount,
								regular_trx_id: headerData.regular_trx_type === '1' ? headerData.regular_trx_id : (headerData.regular_trx_type === '2' ? process.env.VUE_APP_REGULAR_TRX_ID_PLACEHOLDER : 0),
								overallDebit: headerData.overallDebit.replace(/,/g, ''),
								overallCredit: headerData.overallCredit.replace(/,/g, ''),
								payee_id: payeeItemsHeaders,
								remarks: headerData.remarks,
								status: this.getStatus(type, headerData.auto_debit),//type === 'create' ? ((headerData.auto_debit == '1') ? 6 : 3) : 1,
								document_date: this.$data.document_type == 'CW' ? this.$data.date : headerData.document_date,
								is_confidential: this.$data.isConfidential,
								document_type: this.$data.document_type,
								bank_account_id: this.$data.bank_account_id,
								edit_by: this.currUser.id,
								regtrx_indicator: headerData.regtrx_indicator,
								payee_account_id: headerData.payee_account_id,
								reference_number: this.$data.document_type == 'CW' ? headerData.reference_number : '',
								due_date: headerData.due_date,
								auto_debit: (headerData.auto_debit !== undefined) ? headerData.auto_debit : 0,
								cheque_date: headerData.cheque_date,
								cheque_number: headerData.cheque_number,
								cheque_amount: headerData.cheque_amount,
								voucher_amount: headerData.voucher_amount,
								voucher_date: headerData.due_date
							});

							headerData.details.forEach(detail => {
								data.details.push({
									batch_number: this.$data.batch_number,
									account_code: detail.account_code,
									branch_id: detail.branch_id,
									credit_amount: detail.credit_amount,
									debit_amount: detail.debit_amount,
									department_id: detail.department_id,
									document_date: this.$data.document_type == 'CW' ? this.date : detail.document_date,
									document_number: detail.document_number,
									payee_id: detail.payee_id,
									regionalhead_id: detail.regionalhead_id,
									currency_id: detail.currency_id,
									remarks: detail.remarks,
									edit_by: this.currUser.id,
									section_id: (detail.section_id !== undefined || detail.section_id !== '') ? detail.section_id : 0,
									reference_number: this.$data.document_type == 'CW' ? (Object.keys(this.po_request).length !== 0 ? this.po_request.po_number : headerData.reference_number ) : ''
								});
							});
						});
					}					
				}

				for( var i = 0; i < this.upload_files.length; i++ ){
					let file = this.upload_files[i];
					data.image_title.push(file.name);
					data.upload_file.push(file);
				}
				
				switch(type) {
				case 'create':
					this.$data.method_type = 'Save';
					this.executeEntries(data.details, data, 'save-disbursement', 'disbursement');
					break;
				case 'draft':
					this.$data.method_type = 'Save as Draft';
					this.executeEntries(data.details, data, 'save-disbursement-draft', 'disbursement');
					break;
				}
			} else {
				this.snackbar = true;
				this.createDialog = false;
			}
		},
		saveRegularTrx(filterRegTrx, data) {
			let regularTrx = {};

			filterRegTrx.forEach((trxData) => {
				const filterDetails = data.details.filter(detail => detail.document_number === trxData.document_number);
				let acctEntriesData = [];
				acctEntriesData = [];

				let payeeItems = trxData.payee_id;

				if (typeof trxData.payee_id === 'number') {
					payeeItems = trxData.payee_id;
				} else if (typeof trxData.payee_id === 'object') {
					payeeItems = trxData.payee_id.id;
				}

				
				filterDetails.forEach((detail) => {
					let sec_id = detail.section_id;
					
					if (detail.section_id === '' || detail.section_id === undefined){
						sec_id = 0;
					}

					acctEntriesData.push({
						'analysis': detail.payee_id,
						'department': detail.department_id,
						'currency_id': detail.currency_id,
						'branch': detail.branch_id,
						'section': sec_id,
						'account_code': detail.account_code,
						'debit': parseFloat(detail.debit_amount.replace(/,/g, '')),
						'credit': parseFloat(detail.credit_amount.replace(/,/g, ''))
					});
				});

				regularTrx = {
					txn_type: trxData.txn_type,
					payee_name: '1',
					payee_id: payeeItems,
					payee_bank_id: '1',
					payee_bank_name: '1',
					atc_code: trxData.atc_code,
					atc_rate: '10.5500',
					remarks: trxData.remarks,
					document_type: this.$data.document_type,
					acct_entries: JSON.stringify(acctEntriesData),
					edit_by: this.currUser.id
				};

				if (trxData.regular_trx_type === '2') {
					regularTrx.doc_number = trxData.document_number;
					regularTrx.is_approved = 0;

					this.executeEntries(filterDetails, regularTrx, 'save-regulartrx', 'regular-trx');
				} else {
					regularTrx.id = trxData.regular_trx_id;

					this.executeEntries(filterDetails, regularTrx, 'update-regulartrx', 'regular-trx');
				}

				if(filterRegTrx.payee_account_id === '' || filterRegTrx.payee_account_id === undefined){
					delete filterRegTrx.payee_account_id;
				}
			});
		},
		executeEntries(details, payload, method, type) {
			//Initialize Checkers
			let hasDepts = [];
			let hasBranches = [];
			let hasSection = [];
			let unBalanced = [];
			let hasPayeeBank = [];
			let hasCOA = [];
			let hasRemarks = [];
			let uncollectedAmount = [];
			let partialPayment = [];
			let diffCurrency = [];
			// let uniqueTitle = [];

			details.forEach(detail => {
				if (detail.department_id === undefined || detail.department_id === '' || detail.department_id === 0) {
					hasDepts.push({dept: false});
				}

				if (detail.branch_id === undefined || detail.branch_id === '') {
					hasBranches.push({branch: false});
				} 

				if(detail.account_code === undefined || detail.account_code === ''){
					hasCOA.push({coa: false});
				}
				
				if (detail.department_id){
					let det = this.dataDepts.filter(dept => { return dept.id === detail.department_id; });
					if(det.length !== 0){
						if(det[0].sections.length !== 0 && detail.section_id === '' || det[0].sections.length !== 0 && detail.section_id === undefined){
							hasSection.push({department: det[0].dept_desc, section: false});
						}
					}
				}

				if(detail.account_code === undefined || detail.account_code === ''){
					hasCOA.push({coa: false});
				} 

				if(detail.account_code){
					let det = this.coaData.filter(coa => {return coa.acct_code === detail.account_code;});
					if(det[0].currency_id !== this.currency_id){
						diffCurrency.push({currency: false});
					}
					detail.currency_id = det[0].currency_id;
				}
			});

			switch(type) {
			case 'regular-trx':
				if(payload.remarks === '' || payload.remarks === undefined){
					hasRemarks.push({remarks: true});
				}

				if(payload.atc_code === '' || payload.atc_code === undefined || payload.atc_code === null){
					payload.atc_code = 0;
				}

				// if(payload.txn_type)
				
				break;
			case 'disbursement':
				payload.headers.forEach(headerObject => {
					if(headerObject.overallDebit != headerObject.overallCredit){
						unBalanced.push({unbal: true});
					}
					// console.log(this.po_request.amount + ' ' + this.po_request.payment_amount);
					if(Object.keys(this.po_request).length !== 0){
						if(parseFloat(this.payment_amount.replace(/,/g, '')) > (parseFloat(this.po_request.amount.replace(/,/g, '')) - parseFloat(this.po_request.payment_amount.replace(/,/g, '')))){
							uncollectedAmount.push({uncollect: true});
						}
						if(this.po_request.is_partial_payment == 0 && (parseFloat(this.payment_amount.replace(/,/g, '')) != parseFloat(this.po_request.amount.replace(/,/g, '')))){
							partialPayment.push({partial: true});
						}
					}

					if(headerObject.remarks === '' || headerObject.remarks === undefined){
						hasRemarks.push({remarks: true});
					}

					if(headerObject.payeeItemsHeaders){
						let pay = this.payees.filter(pay => { return pay.id === headerObject.payeeItemsHeaders; });
						if (pay[0].payee_banks.length !== 0) {
							if(headerObject.payee_account_id === '' || headerObject.payee_account_id === undefined){
								hasPayeeBank.push({payee: false});
							}
						}
					}
				});
				break;
			}
			// console.log(payload);

			var options = {
				initialFormData: new FormData(),
				showLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			};

			var convertedFormData = jsonToFormData(payload, options);

			if (hasDepts.length != 0) {
				this.snackbar = true;
				this.$data.error_message = 'Department is missing from the field.';
				this.createDialog = false;
			} else if (hasBranches.length != 0) {
				this.snackbar = true;
				this.$data.error_message = 'Branch is missing from the field.';
				this.createDialog = false;
			} else if (hasSection.length != 0) {
				this.snackbar = true;
				this.$data.error_message = 'Section is required in '+ hasSection[0].department +'.';
				this.createDialog = false;
			} else if (hasCOA.length != 0) {
				this.snackbar = true;
				this.$data.error_message = 'COA is missing from the field.';
				this.createDialog = false;
			} else if(unBalanced.length != 0 && method != 'save-disbursement-draft'){
				this.snackbar = true;
				this.$data.error_message = 'Unbalanced Entries.';
				this.createDialog = false;
			} else if(uncollectedAmount.length != 0){
				this.snackbar = true;
				this.$data.error_message = 'Payment Amount is greater than unpaid amount.';
			} else if(partialPayment.length != 0){
				this.snackbar = true;
				this.$data.error_message = 'Payment Amount should be equal to PO amount.';
			} else if (hasRemarks.length != 0) {
				this.snackbar = true;
				this.$data.error_message = 'Remarks is missing from the field.';
				this.createDialog = false;
			} else if(hasPayeeBank.length != 0){
				this.snackbar = true;
				this.$data.error_message = 'Payee Bank is missing from the field.';
				this.createDialog = false;
			} else if(diffCurrency.length != 0){
				this.snackbar = true;
				this.$data.error_message = 'Transaction detail currency is different from header.';
				this.createDialog = false;
			// } else if(this.upload_files.length == 0){
			// 	this.snackbar = true;
			// 	this.$data.error_message = 'Attachment is missing from the field.';
			} else {
				switch(method) {
				case 'save-regulartrx':
					this.$store.dispatch('regularTxns/saveregularTxns', payload);
					break;
				case 'update-regulartrx':
					this.$store.dispatch('regularTxns/updateregularTxns', payload);
					break;
				case 'save-disbursement':
					this.$store.dispatch('trxdisb/saveTrxdisb', {value: convertedFormData, type: payload.headers[0].document_type, distData: this.dist_data});
					break;
				case 'save-disbursement-draft':
					this.$store.dispatch('trxdisb/saveTrxdisb', {value: convertedFormData, type: payload.headers[0].document_type, distData: this.dist_data});
					break;
				}
			}
		},
		async setBatchNumber(id) { 
			let distData = this.dist_data;

			let date = this.getCurrentDate();
			let data = {
				document_date: date,
				edit_by: this.currUser.id
			};

			let unique_id = '';

			switch (id) {
			case 'DM' :
				if('debit_memo' in distData){
					this.setExistingBatchDetails('debit_memo', distData, date);
				} else {
					data['document_type'] = 'DM';
					await this.$store.dispatch('trxdisb/getBatchNumber', data ).then(response => {
						let data = distData.debit_memo;
						data['batch_number'] = response.data.data.batch_number;
						data['disbursement_type'] == id;
						this.$data.batch_number = response.data.data.batch_number;
					}).catch(error => {
						this.error_message = error;
					});

					if (!unique_id) {
						await this.$store.dispatch('trxdisb/getDocumentNumber', data).then(response => {
							unique_id = parseInt(response.data.data.document_number);
						}, error => {
							this.error_message = error;
						});
					}
					
					this.setBatchDetails('debit_memo', distData, data, date, unique_id, id);
				}
				break;
			case 'CV':
				if('cheque' in distData){
					this.setExistingBatchDetails('cheque', distData, date);
				} else {
					this.$store.commit('app/SET_IS_LOADING', true, { root: true });

					let docNumCheque = [];
					
					let chequeDocNumber = this.$store.dispatch('trxdisb/getDocumentNumberCheque').then(response => {
						docNumCheque = response.data.data;
					}, error => {
						this.error_message = error;
					});

					await chequeDocNumber;

					if(docNumCheque.length != 0){
						unique_id = docNumCheque[0].voucher_number;
						data['document_type'] = 'CV';
						this.setBatchDetails('cheque', distData, data, date, unique_id, id);
					}else{
						this.snackbar = true;
						this.$data.error_message = 'No available Voucher Number for Cheque.';
						this.$data.document_type = 'DM';
					}
					this.$store.commit('app/SET_IS_LOADING', false, { root: true });
				}
				break;
			case 'OB':
				if('online' in distData){
					this.setExistingBatchDetails('online', distData, date);
				} else {
					this.$store.commit('app/SET_IS_LOADING', true, { root: true });
					//unique_id = parseInt(distData.debit_memo.document_number[0]);
					unique_id = '';
					data['document_type'] = 'OB';

					this.setBatchDetails('online', distData, data, date, unique_id, id);
				}
				break;
			case 'CW':
				if('cheque_write' in distData){
					this.setExistingBatchDetails('cheque_write', distData, date);
				} else {
					this.$store.commit('app/SET_IS_LOADING', true, { root: true });
					//unique_id = parseInt(distData.debit_memo.document_number[0]);
					unique_id = '';
					data['document_type'] = 'CW';
					
					this.setBatchDetails('cheque_write', distData, data, date, unique_id, id);
				}
				break; 
			}
		},
		async setBatchDetails(disb_type, dist_data, payload, current_date, unique_id, id) {
			await this.$store.dispatch('trxdisb/getBatchNumber', payload).then(response => {
				dist_data[disb_type] = {};
				let data = dist_data[disb_type];
				data['batch_number'] = response.data.data.batch_number;
				data['disbursement_type'] == id;
				this.$data.batch_number = response.data.data.batch_number;
			}, error => {
				this.error_message = error;
			});

			if (!unique_id) {
				payload.batch_number = this.$data.batch_number;
				await this.$store.dispatch('trxdisb/getDocumentNumber', payload).then(response => {
					unique_id = parseInt(response.data.data.document_number);
				}, error => {
					this.error_message = error;
				});
			}

			dist_data[disb_type]['batch_number'] = this.$data.batch_number;
			payload['batch_number'] = this.$data.batch_number;

			dist_data[disb_type]['document_number'] = [unique_id];
			dist_data[disb_type]['disbursement_type'] = id;
			dist_data[disb_type]['headers'] = [{
				company_id: 1,
				batch_number: '',
				regular_trx_type: '1',
				disbursement_type: '1',
				regular_trx_selected: '',
				document_number: unique_id,
				payee_bank: [],
				atc_code: 'WC100',
				currency_id: this.currency_id,
				dollar_rate: this.dollar_rate,
				vat: '12',
				amount: '20000',
				regular_trx_id: '0',
				payee_id: Object.keys(this.po_request).length !== 0 ? this.po_request.payee_id : ''
			}];

			console.log(dist_data[disb_type]['headers']);

			dist_data[disb_type]['details'] = [{
				payee_id: Object.keys(this.po_request).length !== 0 ? this.po_request.payee_id : '',
				section_id: 1,
				regionalhead_id: 1,
				document_number: unique_id,
				document_date: current_date,
				account_code: '',
				debit_amount: 0,
				credit_amount: 0,
			}];

			this.newDetail = { headers: dist_data[disb_type].headers, details: dist_data[disb_type].details};
			this.$store.commit('trxdisb/SET_DIST_DATA', dist_data);
			this.$store.commit('app/SET_IS_LOADING', false, { root: true });
		},
		async getAcctperiodmain(){
			this.dataAcctmainperiod = this.acctperiodmains.data;
			this.nextPageAcctmainperiod= this.acctperiodmains.next_page_url;
			let page = 2;

			while (this.nextPageAcctmainperiod !== null){
				await this.$store.dispatch('acctperiodmain/getData', page).then(response => {
					this.nextPageAcctmainperiod = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataAcctmainperiod.push(details);
					});
				});
				page++;
			}
		},
		async getDataDepts(){
			this.dataDepts = this.depts.data;
			this.nextPageDepts = this.depts.next_page_url;
			let page = 2;

			while (this.nextPageDepts !== null){
				await this.$store.dispatch('dept/getData', page).then(response => {
					this.nextPage = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataDepts.push(details);
					});
				});
				page++;
			}
			let dept = this.dataDepts.filter(e => e.dept_status === 1);
			this.dataDepts = dept;
		},
		async getDataBank(){
			this.dataBanks = this.banks.data;
			this.nextPageBanks = this.banks.next_page_url;
			let page = 2;

			while (this.nextPageBanks !== null){
				await this.$store.dispatch('bank/getData', page).then(response => {
					this.nextPageBanks = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataBanks.push(details);
					});
				});
				page ++;
			}
			
			this.dataBanks.forEach(det => {
				det['code_name'] = det.bank_code + ' - ' + det.bank_acct;
			});
		},
		async getRegTrx(){
			this.dataRegularTrx = this.regulartrx.data;
			this.nextPageRegularTrx= this.regulartrx.next_page_url;
			let page = 2;

			while (this.nextPageRegularTrx !== null){
				await this.$store.dispatch('regularTxns/getData', page).then(response => {
					this.nextPageRegularTrx = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataRegularTrx.push(details);
					});
				});
				page++;
			}
		},
		async getDataCoas(){
			if(this.coaData.length === 0){
				this.dataCoas = this.coas.data;
				this.nextPageCoas = this.coas.next_page_url;
				let page = 2;

				while (this.nextPageCoas !== null){
					await this.$store.dispatch('coa/getCoaData', page).then(response => {
						this.nextPageCoas = response.data.data.next_page_url;
						response.data.data.data.forEach(details => {
							this.dataCoas.push(details);
						});
					});
					page++;
				}
				let data = this.dataCoas.filter(e => parseInt(e.acct_status) === 1);
				this.$store.commit('coa/SET_COA_DATA', data);
			}
		},
		getStatus(type, auto_debit){
			let status = 0;
			if(type === 'create'){
				status = 3;
				if(auto_debit === '1'){
					status = 6;
				} else if (this.currUser.max_amount !== null && parseFloat(this.currUser.max_amount) === parseFloat(this.level1.max_amount)){
					status = 4;
				} else if (this.currUser.max_amount !== null && parseFloat(this.currUser.max_amount) === parseFloat(this.level2.max_amount)){
					status = 5;
				}
			}else{
				status = 1;
			}
			return status;
		},
		viewItem(item) {
			let path = atob(item);
			fetch(path)
				.then(res => res.blob()) 
				.then(blob => {
					const objectURL = URL.createObjectURL(blob);
					window.open(objectURL, '_blank');
				});
		}
	}
};
</script>
<style scoped>

	.create-disb-title {
		color: #FFFFFF;
	}

	.card-action-container {
		display: flex;
		flex-direction: row-reverse;
	}

	.v-card .v-divider.create-disb-divider {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
</style>
